import {Injectable, NgZone} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {SettingsStateModel} from '../../Store/settings.state/settings.state';
import {Logout, SetCompanyInfo, SetLimitedAccessConf} from '../../Store/app.state/app.actions';
import {SetAllSites} from '../../Store/sites.state/sites.actions';
import {ISite} from '../../Store/sites.state/sites.model';
import {Observable, Subject} from 'rxjs';
import {ISettings} from '../../Store/settings.state/settings.model';
import {SetSettings} from '../../Store/settings.state/settings.actions';
import {LibIconsService} from '../lib-icons.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {Select, Store} from '@ngxs/store';
import {SetAllLayers} from '../../Store/layers.state/layers.actions';
import {SetAllZones} from '../../Store/zones.state/zones.actions';
import {IZone} from '../../Store/zones.state/zones.model';
import {isNullOrUndefined} from 'util';
import {MessagesBank, StatusService} from '../status.service';
import {DownloadFileHelper} from '../download.file.helper';
import {AppState} from '../../Store/app.state/app.state';
import {UserDetailsService} from '../user-details.service';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {InputsBindingsModel} from '../../common/Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../common/Forms/Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../common/UI-Components/helperClasses/value-and-display.class';
import {DialogModel} from '../../common/Models/Dialog/dialog.model';
import {DialogService} from '../dialogs.service';
import {UnauthorizedError} from './Error/UnauthorizedError';
import {PlacemarkPreviewService} from '../placemark-preview.service';
import {PermissionsManager} from '../permissions-manager';
import {ApplicationView, ICompanyInfo, ISessionMetaData, IntositeLoginMethod} from 'src/app/common/Models/UI/company-info.interface';
import {APPLICATION_MODE} from 'src/app/Store/app.state/app.model';
import {IntositeEmbeddedService} from '../external/intosite-embedded.service';
import {LAYER_TYPES, LAYER_VISIBILITY} from 'src/app/Store/layers.state/layers.model';
import {OutgoingMessagesService} from '../external/outgoing-messages.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class SessionApiSvc {
  private sessionTimeout: number = 1000 * 60 * 60 * 12;
  private _sessionId: string;
  private timestamp: Date;
  private sessionExpiredMsgDisplayed: boolean = false;

  public accessibleSitesCount = new Subject<number>();

  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;
  constructor(public serverApi: ServerApi, private libIconSrv: LibIconsService, private datePipe: DatePipe,
    private router: Router, private cmdRouterService: CmdRouterService, private store: Store,
    private statusBar: StatusService, private userDetailsService: UserDetailsService,
    private objectSiteValidatorService: ObjectSiteValidatorService, private intositeEmbeddedService: IntositeEmbeddedService,
    public dialogService: DialogService, public zone: NgZone, private placemarkPreviewService: PlacemarkPreviewService,
    private siemensAnalyticsService: SiemensAnalyticsService, private outgoingMessagesService: OutgoingMessagesService) {
    window.addEventListener('beforeunload', this.closeSessionConditinal.bind(this));
    window.addEventListener('hashchange', this.closeSessionConditinal.bind(this));
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        this.sessionId = undefined;
      }
    });
    const refreshIntosite = sessionStorage.getItem("refreshIntosite");
    const intosite_session_counter = +localStorage.getItem('intosite_session_counter');
    const intositeSession = localStorage.getItem('intosite_session');
    if (intositeSession && !refreshIntosite && intosite_session_counter < 1) {
      this.closeSession();
    }
  }

  private async closeSessionConditinal(): Promise<any> {
    this.placemarkPreviewService.closeAllPreviewWindows();
    const counter: number = +localStorage.getItem('intosite_session_counter');
    // if user is on main page, then only set "refreshIntosite" & decrement counter as it is going to increament in main page
    if (this.router.url == "/" || this.router.url.match("/Tour")) {
      sessionStorage.setItem("refreshIntosite", "true");
      localStorage.setItem('intosite_session_counter', (counter - 1).toString());
    }

    if (this.router.url == "/" && !this.router.url.match("/Tour")) {
      // Siemens Analytics end session
      this.siemensAnalyticsService.endSession();
    }

    if (counter === 1) {
      const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
      await this.saveSettings(settings);
    }
  }

  private definedObjectToSiteForAllLayers(layers: any /*from context response*/ ): void {
    layers.forEach((layer: any) => {
      this.objectSiteValidatorService.defineObjectToSite(layer.id.value, ApiTools.defaultSiteId);
      if (layer.children && layer.children.length > 0) {
        this.definedObjectToSiteForAllLayers(layer.children);
      }
      if (layer.layers && layer.layers.length > 0) {
        this.definedObjectToSiteForAllLayers(layer.layers);
      }
    });
  }

  public expiredSessionMessage() {
    this.placemarkPreviewService.closeAllPreviewWindows();
    this.outgoingMessagesService.intositeSessionExpired();

    if (this.sessionExpiredMsgDisplayed) {
      return;
    }
    const inputsBinding: InputsBindingsModel = new Map([
      ['type', 'warning'],
      ['title', 'Intosite Session Expired'],
      ['message', `Your Intosite session has expired.<br/> Please login to the application again.`]
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

    this.sessionExpiredMsgDisplayed = true;

    dialogComp.buttonsInfo = [
      new ButtonInfo('ok', 'Login'),
    ];
    dialog.onClose$().subscribe((model: DialogModel) => {
      this.sessionExpiredMsgDisplayed = false;
      if (model.userAction === 'ok') {
        this.zone.run(() => this.closeSessionAfterUnauthorization());
      }
    });
  }

  public get sessionId(): string {
    if (new Date().getTime() - this.timestamp.getTime() <= this.sessionTimeout) {
      return this._sessionId;
    } else {
      this.expiredSessionMessage();
      throw new UnauthorizedError();
    }
  }

  public set sessionId(val: string) {
    this.timestamp = new Date();
    this._sessionId = val;
  }

  public async setCurrentSite(siteId: number): Promise<void> {
    try {
      const setCurrentSiteUrl: string = `${environment.serverUrl}/services/SiteServices/setCurrentSite`;
      const metaData = {siteId};
      const response: any = await this.serverApi.sendPostToServer(setCurrentSiteUrl, new PostRequest(metaData)).toPromise();
      console.log('setCurrentSite response', response);
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in setting current site. Please try later');
    }
  }

  public getActivityLogs(objectId: string): Observable<any> {
    const getActivityLogsUrl: string = `${environment.serverUrl}/services/InfoElementServices/getActivityLogs?&siteId=${ApiTools.defaultSiteId}&objectId=${objectId}`;
    return this.serverApi.sendGetToServer(getActivityLogsUrl);
  }

  public async getUserSettings(): Promise<any> {

    try {
      const myUserSettingsUrl: string = `${environment.serverUrl}/services/UserServices/getUserSettings?&siteId=${ApiTools.defaultSiteId}`;
      const settingsObj: any = await this.serverApi.sendGetToServer(myUserSettingsUrl).toPromise();
      const settings: ISettings = {
        lastViewpoint: ApiTools.convertViewpointFromResponseToClient(settingsObj.lastView),
        dialogsViewedState: settingsObj.dialogsViewedState,
        sitesExpandState: settingsObj.sitesExpandState,
        generalLayerState: settingsObj.generalLayerState,
        layerState: settingsObj.layerState,
        zoneState: settingsObj.zoneState
      };
      this.serverApi.storeDispatch(new SetSettings(settings));
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading user settings. Please try later');
    }

  }

  async getLibraryIcons(): Promise<void> {
    try {
      const requestUrl: string = `${environment.serverUrl}/services/LibraryElementServices/getLibraryIcons?`;
      const iconResponse: any[] = await this.serverApi.sendGetToServer(requestUrl).toPromise();
      this.libIconSrv.clear();
      iconResponse.forEach((icon) => {
        this.libIconSrv.setLibIcon(icon.id.value, icon.name.value, icon.icon.value, icon.icon.fileName);
      });
    } catch (err){
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading library icons. Please try later');
    }

  }

  async saveSettings(settings: SettingsStateModel): Promise<any> {
    try {
      if (ApiTools.defaultSiteId) {
        const setSettingsUrl: string = `${environment.serverUrl}/services/UserServices/setUserSettings?&siteId=${ApiTools.defaultSiteId}`;
        const results: any = await this.serverApi.sendPostToServer(setSettingsUrl, new PostRequest({}, settings)).toPromise();
        console.log(results);
      }
    } catch (err) {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in saving settings. Please try later');
    }

  }

  getActivityReport(startDate: Date, endDate: Date): void {
    this.serverApi.statusBar.addNewStatus(MessagesBank.EXPORTING_AR);
    this.datePipe.transform(startDate, 'dd-MMM-yyyy');
    const getActivityReportUrl: string = `${environment.serverUrl}/services/UserServices/activityReport?&siteId=${ApiTools.defaultSiteId}&startDate=${this.datePipe.
      transform(startDate, 'dd-MMM-yyyy')}&endDate=${this.datePipe.transform(endDate, 'dd-MMM-yyyy')}`;
    this.serverApi.sendGetToServer(getActivityReportUrl).subscribe((response: any) => {
      let urlObject: string = response.url.value;
      if (!urlObject || urlObject === '') {
        return;
      }
      this.serverApi.statusBar.removeStatus(MessagesBank.EXPORTING_AR);
      console.log('Export file: ', urlObject);

      DownloadFileHelper.downloadFile(urlObject, response.url.fileName);

      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_UserActivityReport', false);
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading activity report. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EXPORTING_AR);
      });
  }

  getUsersAccessRights(email: string, closeSubject: Subject<boolean>): void {
      const getUsersAccessRightReportUrl: string = `${environment.serverUrl}/services/UserServices/exportUsersAccessRightReport?SITEID=${ApiTools.defaultSiteId}`;
      const data = {
        'email': email,
      }
      this.serverApi.sendPostToServer(getUsersAccessRightReportUrl, data).subscribe((response: any) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.EXPORTING_USERS_ACCESS_RGIHT);
        this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.EXPORTING_USERS_ACCESS_RGIHT_WAIT_FOR_EMAIL, 5000);
        closeSubject.next(true);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_UsersAccessRights', false);
      },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in exporting users access rights. Please try later');
          this.serverApi.statusBar.removeStatus(MessagesBank.EXPORTING_USERS_ACCESS_RGIHT);
        });
  }

  async logout(): Promise<any> {
    try {
      const logoutUrl: string = `${environment.serverUrl}/services/UserServices/performLogout?`;
      const res: any = await this.serverApi.sendGetToServer(logoutUrl).toPromise();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }

  public clearLocalStorageItems() {
    localStorage.removeItem('intosite_session');
    localStorage.removeItem('webshareSession');
    localStorage.removeItem('googleBlocked');
    localStorage.removeItem('intosite_session_counter');
  }

  public async closeSession(appView: ApplicationView = ApplicationView.Main): Promise<any> {
    this.outgoingMessagesService.intositeLogout();
    this.placemarkPreviewService.closeAllPreviewWindows();
    if (this.router.url !== '/login') {
      const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
      this.cmdRouterService.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.AFTER_UNLOAD);
      await this.saveSettings(settings);
      await this.logout();
      // clean local storage
      this.clearLocalStorageItems();
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_Logout', false);
      // clean cookies
      document.cookie.split(';').forEach((c) => { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });

      this.store.dispatch(new Logout());
      this.statusBar.resetAll();

      if (appView == ApplicationView.Tour) {
        this.router.navigate(['tourLogoutLanding']);
      } else {
        this.redirectToAuthenticationPage();
      }
    }
  }

  closeSessionAfterUnauthorization(): void {
    this.cmdRouterService.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.AFTER_UNLOAD);
    localStorage.removeItem('intosite_session');
    localStorage.removeItem('webshareSession');
    localStorage.removeItem('intosite_session_counter');
    // clean cookies
    document.cookie.split(';').forEach((c) => { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });
    this.store.dispatch(new Logout());
    this.statusBar.resetAll();
    this.redirectToAuthenticationPage();
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Logout', false);
  }

  public async getSessionContext(zoneAndLayersOnly: boolean = false): Promise<void> {
    try {
      let getContextUrl: string = `${environment.serverUrl}/services/SiteServices/getContext?&isZoneAndLayersOnly="${zoneAndLayersOnly}"`;
      if (zoneAndLayersOnly) {
        getContextUrl += `&siteId=${ApiTools.defaultSiteId}`;
      }
      const contextResponse: any = await this.serverApi.sendGetToServer(getContextUrl).toPromise();
      console.log('contextResponse', contextResponse);

      const activeAppMode: APPLICATION_MODE = this.store.selectSnapshot<APPLICATION_MODE>((state: any) => state.StateApp.activeAppMode );

      // handle sites
      if (!zoneAndLayersOnly) {
        const sites: ISite[] = [];
        contextResponse.sites.forEach((site: any) => {
          const siteStruct: ISite = ApiTools.convertSiteFromResponseToClient(site);
          sites.push(siteStruct);
          if (site.default) {
            ApiTools.defaultSiteId = site.id.value;
          }
        });
        if (sites.length > 0) {
          this.store.dispatch(new SetAllSites(sites));
          this.accessibleSitesCount.next(sites.length);
        } else {
          this.accessibleSitesCount.next(0);
        }
      } else {
        if (!isNullOrUndefined(contextResponse.siteId) && contextResponse.siteId !== ApiTools.defaultSiteId) {
          return;
        }
      }

      // handle zones
      if (!isNullOrUndefined(contextResponse.zones)) { // zones can be null when there is no default site exists
        const zones: IZone[] = [];
        contextResponse.zones.forEach((zone) => {
          const zoneData: IZone = ApiTools.convertZoneFromResponceToClient(zone);

          // Embedded Intosite - Set Visibility as per viewpoint.
          const viewpointSelectedZones = this.intositeEmbeddedService.viewpointSelectedZones;
          if (activeAppMode === APPLICATION_MODE.EMBEDDED && viewpointSelectedZones.size > 0) {
            zoneData.visible = viewpointSelectedZones.has(zoneData.id) && viewpointSelectedZones.get(zoneData.id) ? true : false;
          }

          // this.serverApi.storeDispatch(new SetZone(zoneData));
          this.objectSiteValidatorService.defineObjectToSite(zoneData.id, ApiTools.defaultSiteId);
          zones.push(zoneData);
        });
        if (zones.length > 0 ) {
          this.store.dispatch(new SetAllZones(zones));
        }
      }

      // handle layers
      if (!isNullOrUndefined(contextResponse.layers)) { // layers can be null when there is no default site exists
        console.log('context - layersResponse', contextResponse.layers);
        this.definedObjectToSiteForAllLayers(contextResponse.layers);

        // Embedded Intosite - Set Visibility as per viewpoint.
        const viewpointSelectedLayers = this.intositeEmbeddedService.viewpointSelectedLayers;
        if (activeAppMode === APPLICATION_MODE.EMBEDDED && viewpointSelectedLayers.size > 0) {
          contextResponse.layers.forEach( (layer: any) => {
            this.updateVisibilityOfLayersAsPerViewpoint(layer, viewpointSelectedLayers);
          });
        }
        this.store.dispatch(new SetAllLayers(contextResponse.layers));
      }
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading context data. Please try later');
    }
  }

  public updateVisibilityOfLayersAsPerViewpoint(layer: any, viewpointSelectedLayers) {
    if (layer.layerType !== LAYER_TYPES.Group && layer.layerType !== LAYER_TYPES.General) {
      layer.state.visible = viewpointSelectedLayers.has(layer.id.value) && viewpointSelectedLayers.get(layer.id.value) ? LAYER_VISIBILITY.VISIBLE : LAYER_VISIBILITY.UNVISIBLE;
    }
    if (layer.layers && layer.layers.length > 0) {
      layer.layers.forEach((child) => {
        this.updateVisibilityOfLayersAsPerViewpoint(child, viewpointSelectedLayers);
      });
    }
  }

  async getCurrentUser(): Promise<void> {
    try {
      const getUserUrl: string = `${environment.serverUrl}/services/UserServices/getCurrentUser`;
      const userDetails: any = await this.serverApi.sendGetToServer(getUserUrl).toPromise();
      this.userDetailsService.setUser(userDetails.name.value, userDetails.family.value, userDetails.roles[0].value, userDetails.userName.value, userDetails.candidate.value,
        userDetails.title.value, userDetails.phoneNumber.value,
        userDetails.department.value, userDetails.email.value, userDetails.company.value, userDetails.reasonToAccessSiegle.value, userDetails.id.value);
    } catch (err){
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading current user data. Please try later');
    }
  }

  async getUserSessionInfo(): Promise<any> {
    try {
      const getUserUrl: string = `${environment.serverUrl}/services/UserServices/usersessioninfo`;
      const userSessionInfo: any = await this.serverApi.sendGetToServer(getUserUrl).toPromise();
      ApiTools.userRoles = userSessionInfo.userRoles;

      const companyInfo: ICompanyInfo = userSessionInfo.companyInfo
      companyInfo.userName = userSessionInfo.userName;
      companyInfo.intositeVersion = userSessionInfo.intositeVersion;
      this.store.dispatch(new SetCompanyInfo(companyInfo));

      this.store.dispatch(new SetLimitedAccessConf(userSessionInfo.limitedAccessConfigurations));
     return userSessionInfo;
    } catch (err){
      return null;
    }
  }

  public redirectToAuthenticationPage(): void {
    const sessionMetaData: ISessionMetaData = this.getSessionMetaData();
    let redirectUrl = `${environment.windowOrigin}${environment.baseHref}`;
    if (sessionMetaData && sessionMetaData.ssoLogin) {
      redirectUrl = redirectUrl + `/ssologin?company=${sessionMetaData.company}`;
      if(sessionMetaData.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
        redirectUrl =  `${redirectUrl}&${IntositeLoginMethod.Enterprise}=true`;
      } else if (sessionMetaData.intositeLoginMethod == IntositeLoginMethod.Sphere) {
        redirectUrl = `${redirectUrl}&${IntositeLoginMethod.Sphere}=true`;
      }
    }

    let params = this.router.url.split("?");
    if (params.length > 1) {
      if (redirectUrl.includes('?')) {
        redirectUrl = `${redirectUrl}&${params[1]}`;
      } else {
        redirectUrl = `${redirectUrl}?${params[1]}`;
      }
    }

    window.location.assign(redirectUrl);
  }

  public loginSucceeded(res: any, appView: ApplicationView, copyLinkParams: any = {}): void {
    console.log('loginSucceeded', res);

    sessionStorage.removeItem("refreshIntosite");

    const sessionId: string = res['sessionId'].value;
    localStorage.setItem('intosite_session_counter', '1');
    ApiTools.sessionIndex = 1;
    localStorage.setItem('intosite_session', sessionId);
    this.sessionId = sessionId;

    localStorage.setItem('googleBlocked', res['googleBlocked']);
    console.log('RES userRoles: ', res['userRoles']);
    ApiTools.userRoles = res['userRoles'];
    ApiTools.isThroughLoginPage = true;
    PermissionsManager.initManager(sessionId);
    PermissionsManager.initPermBeforeGetPermFromServer();
    this.setSessionMetaData(appView, res['companyInfo']);
    console.log('NAVIGATING to main from login');
    this.router.navigate([appView], { queryParams: { token: null, company: null, Enterprise: null, Sphere: null, user: null, errorCode: null, setnewpassword: null, ...copyLinkParams }, queryParamsHandling: 'merge' });
  }

  public setSessionMetaData(appView: ApplicationView, companyInfo: ICompanyInfo): void {
    const sessionMetaData: ISessionMetaData = { appView: appView };
    if (companyInfo) {
      sessionMetaData.ssoLogin = companyInfo.ssoLogin;
      sessionMetaData.ssoEnable = companyInfo.ssoEnable;
      sessionMetaData.intositeLoginMethod = companyInfo.intositeLoginMethod;
      sessionMetaData.company = companyInfo.name;
    }
    localStorage.setItem('sessionMetaData', JSON.stringify(sessionMetaData));
  }

  public getSessionMetaData(): any {
    const sessionMetaData: any = localStorage.getItem('sessionMetaData');
    if (sessionMetaData) {
      return JSON.parse(localStorage.getItem('sessionMetaData'));
    }
    return {};
  }

  sendHeartBeats(sessionId: string): void {
    this.serverApi.sendPostToServer(`${environment.serverUrl}/newapi/heartbeat`, null)
    .subscribe(()=> console.log("heartbeat sent for sessionId :" + sessionId));
  }

}
