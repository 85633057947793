<div class="upperSection">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Sites</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="closePanel.emit()"></mat-icon>
    </div>
  </div>
  <div class="searchContainer">
    <input class="searchField" type="text" [(ngModel)]="filterStr" placeholder="Search">
    <div *ngIf="filterStr !== ''" class="clearButton" (click)="clearFilterStr()">clear</div>
    <mat-icon class="searchIcon" svgIcon="sites.search"></mat-icon>
  </div>
  <div class="treeControllers">
    <span (click)="expandAll()" id="expandAllSites" class="textButtonOnPanel">Expand All</span>
    <span class="buttonSeperator"></span>
    <span (click)="collapseAll()" id="collapseAllSites" class="textButtonOnPanel">Collapse All</span>
  </div>
</div>
<div class="middleSection" [class.noContent]="(getSites() | async).length === 0">
  <div *ngIf="(getSites() | async).length === 0" class="noContentAvailable">{{noContentAvailableStr}}</div>
  <perfect-scrollbar *ngIf="(getSites() | async).length !== 0">
    <ins-expansion-box *ngFor="let site of getSites() | async" [titleHeight]="'auto'" [expandedListener]="notifyExpandAll" [expanded]="sitesExpandedState[site.id]"
      [elementOnPanel]="true" [leftPositionedArrow]="true" [titleStyle]="{margin: '4px 0'}" (expandChanged)="expansionChanged(site.id, $event)">
      <div titleContent style="white-space: normal">{{site.name}}</div>
      <mat-icon *ngIf="!site.isDefault" hoverContent class="controlButton padRight8 loadSite" svgIcon="sites.load" (click)="loadSite(site)"
                insTooltip [delayMs]="700" [cfg]="{text: loadSiteTooltip, position: {H: 'hLeft', V:'vTop'}, type: 'info'}"></mat-icon>
      <div content class="expandedSite">
        <div class="siteDesc">{{site.description}}</div>
        <div class="siteImgControllers">
          <img src={{site.imgUrl}} width="60px" height="60px" alt="Site Icon">
          <mat-icon id="editSite" *ngIf="isPermEditSite" class="controlButton" svgIcon="sites.edit" (click)="editSite(site)"></mat-icon>
          <mat-icon id="deleteSite" *ngIf="isPermDeleteSite && !site.isDefault" class="controlButton" svgIcon="sites.delete" (click)="deleteSite(site)"></mat-icon>
        </div>
      </div>
    </ins-expansion-box>
  </perfect-scrollbar>
</div>

<div class="lowerSection itemControllers">
  <div *ngIf="isPermAddSite" id="addSite" class="textButtonOnPanel" (click)="addNewSite()">Add Site</div>
</div>
