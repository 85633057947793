<div class="dialog-header">
  <div class="dialogTitle">Tour Management</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <div class="listContainer">
    <ins-list [value]="toursToShow">
      <ng-template #rowContent let-obj>
        <div  class="tourItem" (mouseenter)="onMouseHover(obj)" (mouseleave)="onMouseLeaveHover(obj)">
          <div class="tourName" insTooltip [cfg]="{text: showTooltip ? toursToShow.get(obj).name: '', position: {H: 'hCenter', V:'vTop'}, type: 'info'}" #rowContentItemName id="{{'model' + obj}}">{{toursToShow.get(obj).name}}</div>
          <div class="tourButtonsContainer">
            <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Public Tour', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="public.tour" (click)="createPublicTour(obj)" *ngIf="showPublicTourLink"></mat-icon>
            <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Copy Tour', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="copy.link" (click)="copyLink(obj)"></mat-icon>
            <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Delete Tour', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="delete.tour" (click)="deleteTour(obj)"></mat-icon>
            <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Open Tour', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="open.tour" (click)="openTour(obj)"></mat-icon>
          </div>
        </div>
      </ng-template>
      <ng-template #emptyRowContent *ngIf="toursToShow.size === 0">
        <div class="noTourAvailable">No tour is available for this site</div>
      </ng-template>
    </ins-list>
  </div>
</div>
<div class="dialog-footer">
  <button class="actionButton blue minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
</div>
