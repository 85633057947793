import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {IValidator} from '../../../Directives/directives.helper';
import {LibIconsService} from '../../../services/lib-icons.service';
export enum IMAGE_SOURCE {
  libIcon,
  localUrl,
  externalUrl,
  selectedFile
}
@Component({
  selector: 'ins-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss', '../shared-UI-components.scss']
})
export class ImagePickerComponent extends BaseUIController {

  @Input() imagesList: string[] = [];
  @Input() unchangeable: boolean = true;
  @Input() imageSource: IMAGE_SOURCE;
  @Output() changeImageSource: EventEmitter<IMAGE_SOURCE> = new EventEmitter();
  showSelector: boolean = false;
  selectorPosition: any = {};
  isExternalImageValid: boolean = false;
  public fileTypeValidator: IValidator[] = [];
  public permittedFilesExtension: string[] = ['.jpg', '.png', '.gif'];
  constructor(private sanitizer: DomSanitizer, public libIconSrv: LibIconsService) {
    super();
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.permittedFilesExtension.join(', ')}];
  }

  openSelector(iconButton: any): void {
    this.selectorPosition['top'] = iconButton.offsetTop + 15 + 'px'; // 15px = half of the icon height
    this.selectorPosition['left'] = iconButton.offsetLeft + 15 + 'px'; // 15px = half of the icon width

    this.showSelector = true;
  }

  onImagePicked(event: any): void {
    this.value = event;
    this.changeImageSource.emit(IMAGE_SOURCE.libIcon);
    this.showSelector = false;
  }

  selectExternalImage($event: any): void {
    if (this.isExternalImageValid) {
      this.value = $event;
      this.changeImageSource.emit(IMAGE_SOURCE.selectedFile);
      this.showSelector = false;
    }
  }

  clickOutside(): void {
    this.showSelector = false;
  }

  stateIfValid($event: any): void {
    this.isExternalImageValid = $event;
  }

  getImageResult(): string | SafeUrl {
    switch (this.imageSource) {
      case IMAGE_SOURCE.libIcon:
        return this.libIconSrv.getLibIconUrlForDialog(this.value);
      case IMAGE_SOURCE.externalUrl:
        return this.sanitizer.bypassSecurityTrustUrl(this.value);
      case IMAGE_SOURCE.selectedFile:
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.value));
      case IMAGE_SOURCE.localUrl:
        return this.value;
      default:
        return '';
    }
  }
}
