<div class="component-wrapper">
  <div *ngIf="label !== ''" id="label" [class.invalid]="!valueValid">
    <span class="reqIcon" id="require" [class.invalid]="!valueValid" #require *ngIf="hasValidtor('require')" > * </span>
    <span>{{label}}</span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"
              class="infoIcon" svgIcon="help.tooltip">
    </mat-icon>
  </div>
  <div id="container">
    <div id = "fileBrowser" *ngIf = "fileType !== 'image/*' || !showWithImageSelector; else imageBrowser" >
      <div id="inputFileNotImageContainer" [class.invalid]="!valueValid" [class.disabled]="disabled" [class.filled]="isFilled">
        <input #fileNameBox id="fileSelectionResult" readonly placeholder="{{placeholder}}"
               [value]="getFileName()" insTooltip [cfg]="{text: showTooltip ? (value ? value.name: ghostName) : '', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"/>
        <mat-icon *ngIf="!valueValid" class="warningIcon" svgIcon="alert.err"
                  insTooltip [cfg]="{text: getValidtor('input').displayText, position: {H: 'hRight', V:'vBottom'}, type: 'err'}">
        </mat-icon>
      </div>
      <div id="button" [class.disabled]="disabled" (click)="getFile()">{{buttonText}}</div>
    </div>
    <ng-template #imageBrowser>
      <div id = "imageBrowser">
        <div *ngIf="value && valueValid; else placeHolderForNewSelection" id="imageContainer">
          <img id="image" [src]="getImageSrc()" alt="img icon">
          <span id="textButtonContainer">
          <label class="textButton" (click)="getFile()">Edit Image</label>
        </span>

        </div>
        <ng-template #placeHolderForNewSelection>
          <div id="emptyImageContainerContainer">
            <div id="emptyImageContainer" (click)="getFile()">
              <mat-icon svgIcon="input-file.browse" ></mat-icon>
            </div>
            <div id="nonValidIconContainer">
              <mat-icon *ngIf="!valueValid" class="warningIcon" svgIcon="warning"
                        insTooltip [cfg]="{text: getValidtor('input').displayText, position: {H: 'hRight', V:'vBottom'}, type: 'err'}">
              </mat-icon>
            </div>
          </div>

        </ng-template>
      </div>
    </ng-template>
    <div class="file-uploader" hidden>
      <input #upfile id="upfile" type="file" [accept]="fileType" (change)="onFileChange()">
    </div>
  </div>
</div>
