<div class="dialog-header">
    <div class="dialogTitle"><mat-icon class="headerIcon" [svgIcon]="visualizationMethod"></mat-icon>Edit Placemark Values</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <div class="tableButtons">
        <mat-icon class="removeRowIcon" svgIcon="removeIcon" *ngIf="selectedRowIndex != -1 && (selectedRow.variableId || selectedRow.name || selectedRow.value)" (click)="removeRow()"></mat-icon>
        <mat-icon class="addNewRowIcon" svgIcon="addIcon" (click)="addNewRow()"></mat-icon>
    </div>
    <div class="ins-table-Container">
        <perfect-scrollbar class="scrollbar">
            <table mat-table [dataSource]="valuesDataSource" matSort class="ins-table">
                <caption></caption>
                <!-- Variable ID Column -->
                <ng-container matColumnDef="variableId">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef>{{ columnNames[0] }}</th>
                    <td mat-cell class="body-cell" *matCellDef="let element">
                        <mat-form-field class="cellText variableIdCell" floatLabel="never">
                            <input [disabled]="disableTableRow(element.value)" matInput [value]="element.variableId" (keyup)="updatePreviewData()" [(ngModel)]="element.variableId" #variableId insTooltip [cfg]="getToolTipConf(variableId, element.variableId)">
                        </mat-form-field>
                    </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef [delayMs]="700" #columnOne insTooltip [cfg]="getToolTipConf(columnOne, columnNames[1])">
                        <mat-icon class="showHideIcon" [svgIcon]="isNameVisible ? 'showIcon' : 'hideIcon'"></mat-icon>{{ columnNames[1] }}
                    </th>
                    <td mat-cell class="body-cell" *matCellDef="let element">
                        <mat-form-field class="cellText" floatLabel="never">
                            <input [disabled]="disableTableRow(element.value)" matInput [value]="element.name" (keyup)="updatePreviewData()" [(ngModel)]="element.name"  #columnOneVal insTooltip [cfg]="getToolTipConf(columnOneVal, element.name)">
                        </mat-form-field>
                    </td>
                </ng-container>
                
                <!-- Value -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef [delayMs]="700" #columnTwo insTooltip [cfg]="getToolTipConf(columnTwo, columnNames[2])">
                        <mat-icon class="showHideIcon" [svgIcon]="isValueVisible ? 'showIcon' : 'hideIcon'"></mat-icon>{{ columnNames[2] }}
                    </th>
                    <td mat-cell class="body-cell" *matCellDef="let element">
                        <mat-form-field class="cellText" floatLabel="never">
                            <input [disabled]="disableTableRow(element.value)" matInput [value]="element.value" [type]="getValueCellDataType(element.value)" (keyup)="updatePreviewData(element)" [(ngModel)]="element.value" #columnTwoVal insTooltip [cfg]="getToolTipConf(columnTwoVal, element.value)">
                        </mat-form-field>
                    </td>
                </ng-container>
                
                <tr mat-header-row class="header-row" *matHeaderRowDef="gridColumns; sticky: true"></tr>
                <tr mat-row class="body-row" (click)="onRowClicked(row, $event, i)" [ngClass]="{ 'highlight-row': i == selectedRowIndex }" *matRowDef="let row; columns: gridColumns; let i = index"></tr>
            </table>
        </perfect-scrollbar>
    </div>
    <div style="position: static" *ngIf="visualizationMethod == statusVisualizationMethods.PIECHART" class="label normalStyle asWarning warningUnderBoxRight">Note: Pie Chart can only have numeric values.</div>

    <ins-radio-button class="componentInDialog" [label]="'Define Placemark Visibility'" [buttonsInfo]="visibilityOptions" [direction]="'column'" [(value)]="visibilityType" [groupName]="'groupForVisibilityOptions'"></ins-radio-button>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Show {{methodTitle}} Preview</div>
        <div content>
            <div class="piechartPreview" *ngIf="visualizationMethod == statusVisualizationMethods.PIECHART">
                <ins-piechart-preview style="width: 100%"
                    [chartName]="visualizationConf.chartName"
                    [showChartName]="visualizationConf.showChartName"
                    [showValue]="visualizationConf.showValue"
                    [showLegend]="visualizationConf.showLegend"
                    [pieDiameter]="175"
                    [colorConf]="visualizationConf.colorConf"
                    [data]="previewData">
                </ins-piechart-preview>
            </div>
            <div class="tablePreview" *ngIf="visualizationMethod == statusVisualizationMethods.TABLE">
                <ins-table-preview 
                    [colDef]="visualizationConf.colDef" 
                    [rowData]="previewData"
                    [rowCount]="visualizationConf.rowCount"
                    [showColumnTitle]="visualizationConf.showColumnTitle" 
                    [showTableHeader]="visualizationConf.showTableHeader" 
                    [tableHeaderName]="visualizationConf.tableHeaderName">
                </ins-table-preview>
            </div>
        </div>
    </ins-expansion-box>
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" (click)="applyChanges()" [disabled]="!isDialogValid">{{buttonsInfo[1].displayValue}}</button>
</div>