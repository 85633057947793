<div class="dialog-header">
    <div class="dialogTitle">Point Cloud Configuration</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="closeDialog(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <div class="label info-text">Define Point Cloud Configuration: </div>
    <div class="actionPanel">
      <div class="searchPanel">
        <div class="searchContainer lightBackground">
          <input class="searchField" type="text" [(ngModel)]="searchStr" placeholder="Enter your search word here..." (keyup)="searchStrChanged(searchStr)">
          <div *ngIf="searchStr !== ''" class="clearButton" (click)="clearSearchStr()">clear</div>
          <mat-icon class="searchIcon" svgIcon="sites.search"></mat-icon>
        </div>
        <div class="filteredDataCount" *ngIf="searchStr">{{filteredDataCount}} results found</div>
      </div>
      <div class="table-buttons">
        <div class="textButton check-conn-link" (click)="testWebShareConnection()" [class.disabled]="webshareAuthPending || !showWebshareTestCon()">Check Connection</div>
        <!-- <mat-icon class="connectIcon icon" svgIcon="connect" [class.disabled]="webshareAuthPending || !showWebshareTestCon()" 
          (click)="testWebShareConnection()" insTooltip [delayMs]="1000" [cfg]="{text: 'Check Connection', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon> -->
        <mat-icon class="removeRowIcon icon" svgIcon="removeIcon" [class.disabled]="selectedRowIndex == -1" (click)="removeRow()"
          insTooltip [delayMs]="1000" [cfg]="{text: 'Remove', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
        <mat-icon class="addNewRowIcon icon" svgIcon="addIcon" (click)="addNewRow()" insTooltip [delayMs]="1000" [cfg]="{text: 'Add', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      </div>
    </div>
    <div class="ins-table-Container">
        <perfect-scrollbar class="scrollbar">
          <table mat-table [dataSource]="confDataSource" matSort class="ins-table" id="webshare-conf-table">
            <caption></caption>
            <!-- Select -->
            <ng-container matColumnDef="select">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header></th>
              <td mat-cell class="body-cell" *matCellDef="let element; let i = index">
                {{i + 1}}
              </td>
            </ng-container>

            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Configuration Name <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <mat-form-field class="cell-text" floatLabel="never">
                  <input matInput (keyup)="dataChanged(element.name)" maxlength="200" [value]="element.name" [(ngModel)]="element.name" [delayMs]="700" #nameDiv insTooltip [cfg]="getToolTipConf(nameDiv, element.name)">
                </mat-form-field>
              </td>
            </ng-container>

             <!-- Login Method -->
            <ng-container matColumnDef="loginMethod">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Login Method <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <div *ngIf="isLogonMethodSelectEnabled(element.loginMethod); else showTextOnly">
                  <ins-selection-box id="select-login-type" class="componentInDialog" (valueChange)="dataChanged($event)" [options]="webshareLoginMethods" [(value)]="element.loginMethod" [label]="''"></ins-selection-box>
                </div>
                <ng-template #showTextOnly>
                  <span style="padding-left: 8px;" *ngIf="element.loginMethod == webshareAuthTypes.Sphere">{{webshareAuthTypes.Sphere}}</span>
                  <span style="padding-left: 8px;" *ngIf="element.loginMethod == webshareAuthTypes.Enterprise">{{webshareAuthTypes.Enterprise}}</span>
                </ng-template>
              </td>
           </ng-container>

            <!-- Load Image Method -->
            <ng-container matColumnDef="loadImageMethod">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Load Image <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <div>
                  <ins-selection-box id="select-login-type" class="componentInDialog" (valueChange)="dataChanged($event)" [options]="webshareLoadImageMethods" [(value)]="element.loadImageMethod" [label]="''"></ins-selection-box>
                </div>
              </td>
           </ng-container>

            <!-- Browser Domain Url -->
            <ng-container matColumnDef="browserDomainUrl">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Browser Domain Url <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <mat-form-field class="cell-text" floatLabel="never">
                  <input matInput (keyup)="dataChanged(element.loginMethod)" [value]="element.browserDomainUrl" [(ngModel)]="element.browserDomainUrl" [delayMs]="700" #browserDomainUrlDiv insTooltip [cfg]="getToolTipConf(browserDomainUrlDiv, element.browserDomainUrl)">
                </mat-form-field>
              </td>
            </ng-container>
            
            <!-- API Domain Url -->
            <ng-container matColumnDef="domainUrl">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> API Domain Url <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <mat-form-field class="cell-text" floatLabel="never">
                  <input matInput (keyup)="dataChanged(element.loginMethod)" [value]="element.domainUrl" [(ngModel)]="element.domainUrl" [delayMs]="700" #domainUrlDiv insTooltip [cfg]="getToolTipConf(domainUrlDiv, element.domainUrl)">
                </mat-form-field>
              </td>
            </ng-container>
          
            <!-- API Key -->
            <ng-container matColumnDef="apiKey">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> API Key <span id="require" class="reqIcon"> * </span></th>
              <td mat-cell class="body-cell" *matCellDef="let element" [ngClass]="{ 'disable-cell': element.loginMethod == webshareAuthTypes.Manual || element.loginMethod == webshareAuthTypes.Enterprise
                              || element.loginMethod == webshareAuthTypes.Sphere}">
                <mat-form-field class="cell-text" floatLabel="never">
                  <input matInput (keyup)="dataChanged(element.loginMethod)" [value]="element.apiKey" [(ngModel)]="element.apiKey" [delayMs]="700" #apiKeyDiv insTooltip [cfg]="getToolTipConf(apiKeyDiv, element.apiKey)">
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row class="header-row" *matHeaderRowDef="confColumns; sticky: true"></tr>
            <tr mat-row class="body-row" (click)="onRowClicked(row, i, $event)" [ngClass]="{ 'disable-row': isConfRowDisabled(row), 'highlight-row-border': i == selectedRowIndex }" *matRowDef="let row; columns: confColumns; let i = index"></tr>
          </table>
          <div *ngIf="!showLoading && noData | async" class="no-records">
            No results found.
          </div>
      
          <div *ngIf="showLoading" class="no-records">
            Loading...
          </div>
        </perfect-scrollbar>
      </div>
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="closeDialog(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid || webshareAuthPending" (click)="saveConfigurations()">{{buttonsInfo[1].displayValue}}</button>
</div> 