<div class="logosHeader">
    <mat-icon class="intosite-logo" svgIcon="intositeBlack"></mat-icon>
    <div class="right-side-logos">
      <img class="sso-company-logo" *ngIf="company" src='assets/login_page/{{company.toLowerCase()}}Logo.png' alt="sso company icon" onerror="this.onerror=null;this.style.display='none';"/>
      <mat-icon class="sie-logo" svgIcon="sie.logo"></mat-icon>
    </div>
  </div>
  <div class="loginContent">
    <div *ngIf="!isChrome && !isFF && !isEdge" class="mainP noLoginBox">Smart Factory at Your Fingertips</div>
    <div class="loginBoxWrapper">
      <div *ngIf="!isChrome && !isFF && !isEdge" class="bestViewed">
        <mat-icon class="chromeIco" svgIcon="chromeIcon"></mat-icon>
        Intosite is best viewed in Chrome
      </div>
      <div *ngIf="!isChrome && (isFF || isEdge)" class="bestViewed">
        <mat-icon class="chromeIco" svgIcon="chromeIcon"></mat-icon>
        Best viewed in Chrome
      </div>
      <div *ngIf="isChrome || isFF || isEdge" class="loginBoxInnerStruct">
        <div class="mainP">Smart Factory at Your Fingertips</div>
        <div class="actionContainer">
          <button class="loginBtn" (click)="redirectToSSO()" *ngIf="!loginInprogress">Login to Intosite {{ tourId ? 'Tour' : ''}}
            with {{getLoginButtonName()}}</button>
          <div class="loginInprogress" *ngIf="loginInprogress">
            Login is in progress
            <span class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </span>
          </div>
        </div>
        <div class="ssoRegisterButton" *ngIf="!loginInprogress">
          <div class="button" *ngIf="company && company.toLowerCase() === 'ford'" (click)="openRegister('https://azureford.sharepoint.com/sites/GMS-Learning/TCE/Intosite_Home/Pages/Home.aspx', 1080, 760)">Register</div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
    <div class="bgImage"></div>
    <div class="gradiBg"></div>
    <!-- <div class="getAppContainer">
      <div class="availableText">Available on mobile</div>
      <div>
        <img class="downloadApp" style="margin-right: 8px;" src="assets/login_page/getAppGoogle.png" (click)="openGetFromGoogle()"/>
        <img class="downloadApp" src="assets/login_page/getAppMs.png" (click)="openGetFromMS()"/>
      </div>
    </div> -->
    <div class="copyRights">
      &copy; Siemens 2022 | Siemens Digital Industries Software Inc.
    </div>
    <div class="termPolicy">
      <span (click)="openPrivacyPolicy()" style="margin-right: 30px;">Privacy Policy</span>
      <span (click)="openTermsOfService()">Terms of Service</span>
    </div>
  </div>
  <div id="dialog-container"></div>
  
  