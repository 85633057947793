import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IScan } from '../import-scans-util';
import { map } from 'rxjs/operators';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';

@Component({
  selector: 'ins-import-scans-scan-grid',
  templateUrl: './import-scans-scan-grid.component.html',
  styleUrls: ['./import-scans-scan-grid.component.scss',
  './../../../../UI-Components/shared-UI-components.scss',
  './../../../../../Viewer/content.container/side-bar.container/subMenus/submenus-shared-design.scss']
})
export class ImportScansScanGridComponent implements OnChanges {
  displayedScanColumns: string[] = ['select', 'DisplayName', 'X', 'Y', 'Z', 'ModificationTime', 'RecordingTime'];
  scanDataSource: MatTableDataSource<IScan>;
  scanFilterStr: string = '';
  selection = new SelectionModel<IScan>(true, []);
  filteredDataCount: number;
  noData;
  @Input('showLoading') showLoading = false;
  @Input('scansToShow') scansToShow: IScan[]
  @Output() scanSelectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() resolutionChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public resolutions: ButtonInfo[] = [
    new ButtonInfo("1024", 'Default Scan resolution is up to 1K'),
    new ButtonInfo("2048", 'Default Scan resolution is up to 2K'),
    new ButtonInfo("4096", 'Default Scan resolution is up to 4K'),
    new ButtonInfo("8192", 'Default Scan resolution is up to 8K'),
    new ButtonInfo("16384", 'Default Scan resolution is up to 16K'),
  ];

  defaultResolution: string = "4096";
  
  ngOnChanges(changes: SimpleChanges): void {
    this.selection.clear();
    if ( changes.scansToShow ) {
      this.scanDataSource = new MatTableDataSource(this.scansToShow);
      this.scanDataSource.sort = this.sort;
      this.noData = this.scanDataSource.connect().pipe(map(data => data.length === 0));

      this.scanDataSource.data.forEach(row => this.selection.select(row));
      this.scanSelectionChanged.emit(this.selection.selected);
    }
  }

  resolutionValueChanged(resolution: string): void {
    this.resolutionChanged.emit(resolution);
  }

  scanFilterChanged(filterValue: string): void {
    this.scanDataSource.filter = filterValue.trim().toLowerCase();
    this.filteredDataCount = this.scanDataSource.filteredData.length;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.scanDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.scanDataSource.data.forEach(row => this.selection.select(row));
  }

  selectionChange(event: MouseEvent, row: IScan): void {
    if (event) {
      this.selection.toggle(row);
    }
    this.updateSelectionToParent();
  }

  masterSelectionChange(event: MouseEvent): void {
    if(event){
      this.masterToggle();
    }
    this.updateSelectionToParent();
  }

  updateSelectionToParent(): void {
    this.scanSelectionChanged.emit(this.selection.selected);
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  clearFilterStr(): void {
    this.scanFilterStr = '';
    this.scanFilterChanged('');
  }

}
