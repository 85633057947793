import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {RemoveSite, SetSite} from '../../Store/sites.state/sites.actions';
import {ISite} from '../../Store/sites.state/sites.model';
import {DECIMAL_RADIX} from '../../common/Definitions/shared.definitions';
import {FilesApiSvc} from './files.api.svc';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {Select} from '@ngxs/store';
import {MessagesBank} from '../status.service';
import {SitesLoaderSvc} from './sites.loader.svc';
import {Observable} from 'rxjs';
import {SitesState} from 'src/app/Store/sites.state/sites.state';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {ValueAndDisplay} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {DialogRef} from 'src/app/common/Forms/Dialog-types/dialog-ref';
import {DialogModel} from 'src/app/common/Models/Dialog/dialog.model';
import {DialogService} from '../dialogs.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class SitesApiSvc implements ISseHandler {
  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  private sites: ISite[];
  constructor(private serverApi: ServerApi, private filesApiSvc: FilesApiSvc,
              private sitesLoaderSvc: SitesLoaderSvc,
              private dialogService: DialogService, private siemensAnalyticsService: SiemensAnalyticsService) {
                this.sites$.subscribe((sites: ISite[]) => this.sites = sites);
              }
  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [SitesApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        this.addNewSiteCallback(msg.object);
        break;
      }
      case ModificationType.UPDATE: {
        this.updateSiteCallback(msg.object);
        break;
      }
      case ModificationType.DELETE: {
        this.deleteSiteCallback(msg.objectId.value);
        break;
      }
      case ModificationType.REFRESH: {
        this.refreshSiteCallback(msg.object.id.value);
      }
    }
  }

  async addNewSite(newImage: File, newSite: ISite): Promise<any> {
    try {
      newSite.imgUrl = await this.filesApiSvc.uploadImage(newImage);
    } catch(err) {
      this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_SITE);
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in creating a site: file upload failed. Please try again later.');
      throw "Error in creating a site: file upload failed. Please try again later.";
    }

    const addNewSiteUrl: string = `${environment.serverUrl}/services/SiteServices/createSite`;

    this.serverApi.sendPostToServer(addNewSiteUrl, new PostRequest({}, newSite)).subscribe((site: any) => {
      const siteStruct: ISite = this.addNewSiteCallback(site);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_CreateSite', true, siteStruct);
      this.sitesLoaderSvc.loadSite(siteStruct, undefined, true);
      this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_SITE);
      this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.PERMISSION_TO_SITE_REMINDER,  5000);
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding site. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_SITE);
      });
  }

  public async getSite(siteId): Promise<any> {
    let site: any = await this.serverApi.sendGetToServer(`${environment.serverUrl}/newapi/sites/${siteId}?`).toPromise();
    return site;
  }


  addNewSiteCallback(site: any): ISite {
    const siteStruct: ISite = ApiTools.convertSiteFromResponseToClient(site);
    this.serverApi.storeDispatch(new SetSite(siteStruct));
    return siteStruct;
  }

  async updateSite(imageFile: File, updateSite: ISite, hideMessage: boolean = true): Promise<any> {
    if (imageFile) {
      try {
        updateSite.imgUrl = await this.filesApiSvc.uploadImage(imageFile);
      } catch(err) {
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_SITE);
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating a site: file upload failed. Please try again later.');
        throw "Error in updating a site: file upload failed. Please try again later.";
      }
    }

    const updateSiteUrl: string = `${environment.serverUrl}/services/SiteServices/updateSite?`;

    this.serverApi.sendPostToServer(updateSiteUrl, new PostRequest({}, updateSite)).subscribe((site: any) => {
      this.updateSiteCallback(site);
      if (hideMessage) {
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_SITE);
      }
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_EditSite');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating site. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_SITE);
      });
  }

  updateSiteCallback(site: any): void {
    const siteStruct: ISite = ApiTools.convertSiteFromResponseToClient(site);
    this.serverApi.storeDispatch(new SetSite(siteStruct));
  }

  // get sites count under company irrespective of user permission
  async getCompanySiteCount(): Promise<any> {
    try {
      const url: string = `${environment.serverUrl}/services/SiteServices/getCompanySiteCount?`;
      return await this.serverApi.sendGetToServer(url).toPromise();
    } catch(err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err,'Error in getting site count for company. Please try later');
    }
  }

  deleteSite(siteId: number): void {
    const removeSiteUrl: string = `${environment.serverUrl}/services/SiteServices/deleteSite?&siteId=${siteId}`;
    this.serverApi.sendGetToServer(removeSiteUrl).subscribe((removedSiteId: string) => {
      this.deleteSiteCallback(removedSiteId);
      this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_SITE);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_DeleteSite');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting site. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_SITE);
      });
  }

  deleteSiteCallback(removedSiteId: string): void {
    const siteIdNum: number = parseInt(removedSiteId, DECIMAL_RADIX);
    this.serverApi.storeDispatch(new RemoveSite(siteIdNum));
  }

  refreshSiteCallback(siteId: string): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      ['type', 'warning'],
      ['title', 'Layer Structure Changes'],
      ['message', 'The administrator has changed the layer structure of your site. <br> Click <b>Reload</b> to continue working with the modification.'],
      ['buttonsInfo', [new ValueAndDisplay('reload', 'Reload')]]
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'reload' || model.userAction === 'cancel') {
        this.reloadSite(siteId);
      }
    });
  }

  reloadSite(siteId: string): void {
    let site: ISite = this.sites.find( (site: ISite) => site.id == parseInt(siteId));
    this.sitesLoaderSvc.loadSite(site, undefined, undefined, undefined, undefined, true);
  };

  async getObjectByExternalId(siteId: string, externalId: string, app: string): Promise<any> {
    try {
      const url: string = `${environment.serverUrl}/newapi/sites/object/${externalId}/${app}?siteId=${siteId}`;
      return await this.serverApi.sendGetToServer(url).toPromise();
    } catch(err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err,'There was an internal error. Please try later');
    }
  }
}
