// import {SelectionModel} from '@angular/cdk/collections';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Select, Store} from '@ngxs/store';
import {Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {ICompanyInfo, IntositeLoginMethod, IWebShareConf, WebshareAuthType, WebshareLoadImageMethod} from 'src/app/common/Models/UI/company-info.interface';
import {ButtonInfo, ValueAndDisplay} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {Validators} from 'src/app/common/Validators/validators';
import {ServerApi} from 'src/app/services/api.services/server.api';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {DialogService} from 'src/app/services/dialogs.service';
import {MessagesBank} from 'src/app/services/status.service';
import {AppState} from 'src/app/Store/app.state/app.state';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'ins-webshare-conf-dialog',
  templateUrl: './webshare-conf-dialog.component.html',
  styleUrls: ['./webshare-conf-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss', './../../../../Viewer/content.container/side-bar.container/subMenus/submenus-shared-design.scss']
})
export class WebshareConfDialogComponent extends BaseDialog implements OnInit {
  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;
  public companyInfo: ICompanyInfo;
  public buttonsInfo: ButtonInfo[] = [];
  
  public confColumns: string[] = ['select', 'name', 'loginMethod', 'loadImageMethod', 'browserDomainUrl', 'domainUrl', 'apiKey'];
  public confDataSource: MatTableDataSource<any>;
  public showLoading: boolean = false;
  public noData;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public selectedRowIndex = -1;
  public selectedConf: IWebShareConf;
  public webshareConfs: IWebShareConf[] = [];

  public filteredDataCount: number = 0;
  public searchStr: string = '';
  public webshareAuthPending: boolean = false;
  public WebShareAuthSubscription = new Subscription();
  public webshareAuthTypes = WebshareAuthType;
  
  public webshareLoginMethods = [
    new ButtonInfo(WebshareAuthType.Manual, 'Manual'),
    new ButtonInfo(WebshareAuthType.Automatic, 'Automatic')
  ];

  public webshareLoadImageMethods = [
    new ButtonInfo(WebshareLoadImageMethod.Client, 'Client'),
    new ButtonInfo(WebshareLoadImageMethod.Server, 'Server'),
    new ButtonInfo(WebshareLoadImageMethod.Auto, 'Auto')
  ];

  // public selection = new SelectionModel<IWebShareConf>(true, []);
  
  private dataChangedSub: Subject<string> = new Subject<string>();
  constructor(public injector: Injector, private serverApi: ServerApi,
    public dialogService: DialogService, public validators: Validators, private store: Store, private webshareApiSvc: WebshareApiSvc) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('save', 'Save'),
    );

    this.dataChangedSub.pipe(
      debounceTime(200))
      .subscribe(data => this.validateConfigurations());

    let companyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
    if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
      this.webshareLoginMethods.push(new ButtonInfo(WebshareAuthType.Enterprise, 'Enterprise'));
    }
    if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
      this.webshareLoginMethods.push(new ButtonInfo(WebshareAuthType.Sphere, 'Sphere'));
    }
  }

  public ngOnInit(): void {
    this.companyInfo$.subscribe((companyInfo: ICompanyInfo) => {
      this.companyInfo = companyInfo;
      let webshareConfs = cloneDeep(companyInfo.webShareConfs);
      this.webshareConfs = webshareConfs.map ((conf: IWebShareConf) => {
        if ( conf.loginMethod == WebshareAuthType.Manual || conf.loginMethod == WebshareAuthType.Automatic) {
          conf.browserDomainUrl = conf.browserDomainUrl ? conf.browserDomainUrl : conf.domainUrl;
          return conf;
        }
        return conf;
      })
      this.loadDataInGrid();
    });
  }

  public defineDialogModel(): void {}

  onChanges(): void {}

  public loadDataInGrid(): void {
    this.confDataSource = new MatTableDataSource(this.webshareConfs);
    this.confDataSource.sort = this.sort;
    this.noData = this.confDataSource.connect().pipe(map(data => data.length === 0));
    this.confDataSource.filterPredicate = function(data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };
  }

  public isLogonMethodSelectEnabled(loginMethod: WebshareAuthType): boolean {
    if (loginMethod == WebshareAuthType.Enterprise && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Enterprise) {
      return false;
    }
    if (loginMethod == WebshareAuthType.Sphere && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Sphere) {
      return false;
    }
    return true;
  }

  // public selectionChange(event: MouseEvent, conf: IWebShareConf, index: number): void {
  //   this.selection.clear();
  //   event ? this.selection.toggle(conf) : null;
  //   if (event['checked']) {
  //     this.onRowSelect(conf, index);
  //   } else {
  //     this.onRowUnselect();
  //   }
  // }

  public onRowUnselect() {
    this.selectedConf = null;
    this.selectedRowIndex = -1;
  }

  public onRowSelect(conf: IWebShareConf, index: number) {
    this.selectedRowIndex = index;
    this.selectedConf = conf;
  }

  public addNewRow() {
    this.webshareConfs.push({companyId: this.companyInfo.id, name: "", domainUrl: "", browserDomainUrl: "", apiKey: "", loginMethod: '', loadImageMethod: ''});
    this.loadDataInGrid();
  }

  public onRowClicked(conf: IWebShareConf, index: number, e: MouseEvent): void {
    if ( e.target['classList'].contains('mat-column-select') ) {
      if(this.selectedRowIndex != -1 && this.selectedRowIndex == index) {
        this.onRowUnselect();
      } else {
        this.onRowSelect(conf, index);
      }
    }
  }

  public removeRow() {
    if (this.selectedConf.id) {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', `Delete "${this.selectedConf.name}" Configuration` ],
        [ 'message', `Deleting configuration "${this.selectedConf.name}" will remove it from assigned sites. Are you sure you want to continue?`]
      ]);
      const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      dialogComp.buttonsInfo = [
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('yes', 'Yes'),
      ];
      dialog.onClose$().subscribe((data) => {
        if (data.userAction === 'yes') {
          this.removeRowFromConfData();
        }
      });
    } else {
      this.removeRowFromConfData();
    }
  }

  public removeRowFromConfData(): void {
    this.webshareConfs.splice(this.selectedRowIndex, 1);
    this.loadDataInGrid();
    this.onRowUnselect();
    this.validateConfigurations();
  }

  public isConfRowDisabled(conf: IWebShareConf): boolean {
    return (conf.id && conf.loginMethod == WebshareAuthType.Enterprise &&
      this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Enterprise) || (conf.id && conf.loginMethod == WebshareAuthType.Sphere &&
        this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Sphere);
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public saveConfigurations(): void {
    this.webshareConfs.map( (conf: IWebShareConf) => {
      conf.domainUrl = conf.domainUrl.trim().replace(/\/$/, "");
      conf.browserDomainUrl = conf.browserDomainUrl.trim().replace(/\/$/, "");
    })

    this.serverApi.statusBar.addNewStatus(MessagesBank.WEBSHARE_CONFIGURATIONS_UPDATE);
    this.webshareApiSvc.updateWebhshareConf(this.companyInfo.id, this.webshareConfs)
      .subscribe((res: any) => {
        if (res.hasErrors) {
          this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(res.errorMessage), res.errorMessage);
        } else {
          this.webshareApiSvc.webshareConfsUpdated(res, false);
          this.close(this.buttonsInfo[1].value);
        }
        this.serverApi.statusBar.removeStatus(MessagesBank.WEBSHARE_CONFIGURATIONS_UPDATE);
      }, (err) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.WEBSHARE_CONFIGURATIONS_UPDATE);
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating Point Cloud Configurations. Please try later');
      }
    );
  }

  public clearSearchStr(): void {
    this.searchStr = '';
    this.searchStrChanged('');
  }

  public searchStrChanged(filterValue: string): void {
    this.confDataSource.filter = filterValue.trim().toLowerCase();
    this.filteredDataCount = this.confDataSource.filteredData.length;
  }

  public testWebShareConnection(): void {
    this.webshareAuthPending = true;  
    this.WebShareAuthSubscription = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( ( success ) => {
        this.WebShareAuthSubscription.unsubscribe();
        this.webshareAuthPending = false;
        if (success) {
          const inputsBinding: InputsBindingsModel = new Map<string, any>([
            [ 'type', 'info'],
            [ 'title', 'Connection Status' ],
            [ 'message', 'Succeeded in connecting to the point cloud hub.'],
            [ 'buttonsInfo', [new ValueAndDisplay('close', 'Close')]]
          ]);

          const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
          const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
        }
    })

    this.webshareApiSvc.loginToWebshare(this.selectedConf);
  }

  public showWebshareTestCon(): boolean {
    if (this.selectedConf) {
      return this.selectedConf.loginMethod == WebshareAuthType.Automatic && this.selectedConf.apiKey != '' && this.selectedConf.domainUrl != '';
    }
    return false;
  }

  public dataChanged(value: string): void {
    this.dataChangedSub.next(value);
  }

  public validateConfigurations(): void {
    let isConfValid = true;
    this.webshareConfs.forEach( (conf: IWebShareConf) => {
      switch(conf.loginMethod) {
        case WebshareAuthType.Manual:
          if (!conf.name || !conf.domainUrl || !conf.browserDomainUrl || !conf.loadImageMethod) {
            isConfValid = false;
          }
          break;
        case WebshareAuthType.Automatic:
          if (!conf.name || !conf.domainUrl || !conf.browserDomainUrl || !conf.apiKey || !conf.loadImageMethod) {
            isConfValid = false;
          }
          break;
        case WebshareAuthType.Enterprise:
          if (!conf.name || !conf.domainUrl || !conf.browserDomainUrl || !conf.loadImageMethod) {
            isConfValid = false;
          }
          break;
        case WebshareAuthType.Sphere:
          if (!conf.name || !conf.domainUrl || !conf.browserDomainUrl || !conf.loadImageMethod) {
            isConfValid = false;
          }
          break;
      }
      if ((conf.name || conf.domainUrl || conf.browserDomainUrl || conf.loadImageMethod) && !conf.loginMethod) {
        isConfValid = false;
      }
    });
    this.updateDialogValidity(isConfValid);
  }

  public updateDialogValidity(valid: boolean): void {
    this.isDialogValid = valid;
  }

  public closeDialog(param: any): void {
    this.webshareApiSvc.cancelCheckConnection();
    this.WebShareAuthSubscription.unsubscribe();
    this.close(param);
  }

}
