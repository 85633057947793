import {ISite} from './sites.model';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {RemoveSite, SetAllSites, SetAllSitesNoDefault, SetSite} from './sites.actions';
import {SetActiveSite} from '../app.state/app.actions';
import { Injectable } from '@angular/core';

export class SitesStateModel {
  sites: ISite[] = [];
}

@State<SitesStateModel>({
  name: 'StateSites',
  defaults: new SitesStateModel()
})
@Injectable()
export class SitesState {

  constructor(private store: Store) {}

  @Selector()
  static getSites(state: SitesStateModel): ISite[] {
    console.log('GETTER - SITES', state.sites);
    return state.sites;
  }

  @Action(SetSite)
  setSite({getState, patchState }: StateContext<SitesStateModel>, { site, patchNewState }: SetSite): void {
    const state = getState();

    const existingSiteIndex: number = state.sites.findIndex((inSsite: ISite) => inSsite.id === site.id);
    if (existingSiteIndex >= 0) {
      state.sites[existingSiteIndex] = site;
    } else {
      state.sites.push(site);
    }

    // Sort By ABC
    state.sites.sort((siteA: ISite, siteB: ISite) => {
      return siteA.name.localeCompare(siteB.name);
    });

    if (site.isDefault) {
      this.store.dispatch(new SetActiveSite(site));
    }

    if (patchNewState) {
      patchState({
        sites: [...state.sites]
      });
    }
  }

  @Action(SetAllSites)
  setAllSites(stateContext: StateContext<SitesStateModel>, { sites }: SetAllSites): void {

    const state = stateContext.getState();

    sites.forEach((site: ISite) => {
      this.setSite(stateContext, new SetSite(site, false));
    });

    stateContext.patchState({
      sites: [...state.sites]
    });
  }

  @Action(RemoveSite)
  remove({getState, patchState }: StateContext<SitesStateModel>, { payload }: RemoveSite): void {
    patchState({
      sites: getState().sites.filter((site) => site.id !== payload)
    });
  }

  @Action(SetAllSitesNoDefault)
  setAllSitesNoDefault({getState, patchState }: StateContext<SitesStateModel>): void {
    const sites: ISite[] = getState().sites;
    sites.forEach((site: ISite) => {
      site.isDefault = false;
    });
    patchState({
      sites
    });
  }
}
