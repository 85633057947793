import {Component, OnInit, Injector, ViewChild, Input, AfterViewInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {ServerApi} from 'src/app/services/api.services/server.api';
import {WebshareLoginApiSvc} from 'src/app/services/api.services/webshare.login.api.svc';
import {IValidator} from 'src/app/Directives/directives.helper';
import {InputBoxComponent} from 'src/app/common/UI-Components/input-box/input-box.component';
import {MessagesBank, StatusService} from 'src/app/services/status.service';
import {ApiTools} from 'src/app/services/api.services/api.tools';
import {IWebShareConf, WebshareAuthType} from 'src/app/common/Models/UI/company-info.interface';

@Component({
  selector: 'ins-webshare-cloud-login',
  templateUrl: './webshare-cloud-login.component.html',
  styleUrls: ['./webshare-cloud-login.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class WebshareCloudLoginComponent extends BaseDialog implements OnInit, AfterViewInit {

  @ViewChild('userNameRef') private userNameRef: InputBoxComponent;
  @ViewChild('passRef') private passRef: InputBoxComponent;
  @ViewChild('loginUrlRef') private loginUrlRef: InputBoxComponent;

  @Input() userName: string = '';
  @Input() password: string = '';
  @Input() webshareConf: IWebShareConf;
  @Input() siteId: number = null;

  public domainUrl: string = '';
  public browserDomainUrl: string = '';
  
  public buttonsInfo: ButtonInfo[] = [];
  public errorMsg: string = null;

  public isSaveCredsChecked: boolean = false;
  public showReloginOption: boolean = false;

  requiredField_name: IValidator = {objectID: 'input', regEx: '', displayText: 'Missing username. Please fill and retry'};
  requiredField_pass: IValidator = {objectID: 'input', regEx: '', displayText: 'Missing password. Please fill and retry'};
  requiredField_url: IValidator = { objectID: 'input', regEx: '^(http|https)://(.)+', displayText: 'Please provide a valid domain URL'};

  constructor(public injector: Injector, private serverApi: ServerApi, private webshareLoginApiSvc: WebshareLoginApiSvc, private statusBar: StatusService) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.webshareConf) {
      this.webshareConf = {
        companyId: null,
        name: "",
        browserDomainUrl: "",
        domainUrl: "",
        loginMethod: WebshareAuthType.Manual,
        loadImageMethod: ""
      }
    }

    this.domainUrl = this.webshareConf.domainUrl;

    this.buttonsInfo.push(
      new ButtonInfo('close', 'Close'),
      new ButtonInfo('continue', 'Login'),
      new ButtonInfo('relogin', 'Re-Login'),
      new ButtonInfo('continue', 'Continue')
    );

    if ( this.userName && this.password && this.domainUrl) {
      this.isSaveCredsChecked = true;
      this.showReloginOption = true;
    }
  }

  ngAfterViewInit(): void {
    this.loginUrlRef.recheckValidity();
  }

  keyPressed(keyEv: KeyboardEvent ): void {
    if (keyEv.keyCode === 13 /*Enter*/) {
      this.performLogin();
    }
  }

  performLogin(): void {
    if (!this.isAllFieldsFilled() || !ApiTools.isValidUrl(this.domainUrl)) {
      return;
    }

    this.statusBar.addNewStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
    this.webshareLoginApiSvc.performWebshareLogin(this.userName, this.password, this.domainUrl)
      .subscribe( user => {
        this.successfulLoginCallback();
        this.statusBar.removeStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
        super.close(this.buttonsInfo[1].value);
      },
      err => {
        if ( err.status === 401 ) {
          this.statusBar.removeStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
          this.errorMsg = 'Some details are incorrect. Try again.';
        } else if ( err.status === 404 ) {
          this.statusBar.removeStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
          this.errorMsg = 'The domain is not valid.';
        } else {
          this.webshareLoginApiSvc.checkWebshareLogin(this.domainUrl)
            .subscribe(data => {
              this.successfulLoginCallback();
              this.statusBar.removeStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
              super.close(this.buttonsInfo[1].value);
            },
            err => {
              super.close(this.buttonsInfo[0].value); 
              this.statusBar.removeStatus(MessagesBank.WEBSHARE_LOGIN_INPROGRESS);
              this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'The server encountered a problem. Please contact your system administrator.');
            })
        }
      });
  }

  successfulLoginCallback() {
    this.webshareConf.domainUrl = this.domainUrl;
    this.webshareLoginApiSvc.setWebshareSession(this.webshareConf);
    if ( this.isSaveCredsChecked ) {
        this.webshareLoginApiSvc.saveScanProjectPartnerDetails(this.userName, this.password, this.browserDomainUrl, this.domainUrl, this.siteId)
          .subscribe( res => console.log("Faro Login credentails saved"))
    } else {
        this.webshareLoginApiSvc.deleteScanProjectPartnerDetails(this.siteId)
          .subscribe( res => console.log("Faro Login credentails cleared"))
    }
  }

  reLogin(): void {
    this.showReloginOption = false;
    this.userName = '';
    this.password = '';
  }

  onInputsChange(): void {
    this.errorMsg = null;
  }

  isAllFieldsFilled(): boolean {
    this.userName = this.userName.trim();
    this.password = this.password.trim();
    this.domainUrl = this.domainUrl.trim();
    if (this.userName === '' || this.password === '' || this.domainUrl === '') {
      this.userNameRef.recheckValidity(true);
      this.passRef.recheckValidity(true);
      this.loginUrlRef.recheckValidity(true);
      return false;
    }

    return true;
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['userName', this.userName],
      ['password', this.password],
      ['webshareConf', this.webshareConf],
      ['siteId', this.siteId]
    );
  }
  
  onChanges(): void {}
}
 