import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {DialogModel} from 'src/app/common/Models/Dialog/dialog.model';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {WebshareAuthType, WebshareLoadImageMethod} from 'src/app/common/Models/UI/company-info.interface';
import {ITemplate} from 'src/app/common/Models/UI/templates.interface';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {Validators} from 'src/app/common/Validators/validators';
import {ApiTools} from 'src/app/services/api.services/api.tools';
import {ServerApi} from 'src/app/services/api.services/server.api';
import {TemplateManagementApiSvc} from 'src/app/services/api.services/template.management.api.svc';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {DialogService, DialogType} from 'src/app/services/dialogs.service';
import {MessagesBank} from 'src/app/services/status.service';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {EditorMode} from '../dialog.helper';
import {NewEditTemplateDialogComponent} from '../new-edit-template-dialog/new-edit-template-dialog.component';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';

@Component({
  selector: 'ins-template-management-dialog',
  templateUrl: './template-management-dialog.component.html',
  styleUrls: ['./template-management-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss', './../../../../Viewer/content.container/side-bar.container/subMenus/submenus-shared-design.scss']
})
export class TemplateManagementDialogComponent extends BaseDialog implements OnInit {
  public buttonsInfo: ButtonInfo[] = [];
  public templateColumns: string[] = ['name', 'actions'];
  public templateDataSource: MatTableDataSource<any>;
  public showLoading: boolean = false;
  public noData; 
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public templates: ITemplate[] = [];

  public filteredDataCount: number = 0;
  public searchStr: string = '';
  public webshareAuthPending: boolean = false;
  public WebShareAuthSubscription = new Subscription();
  public webshareAuthTypes = WebshareAuthType;
  
  public webshareLoginMethods = [
    new ButtonInfo(WebshareAuthType.Manual, 'Manual'),
    new ButtonInfo(WebshareAuthType.Automatic, 'Automatic')
  ];

  public webshareLoadImageMethods = [
    new ButtonInfo(WebshareLoadImageMethod.Client, 'Client'),
    new ButtonInfo(WebshareLoadImageMethod.Server, 'Server'),
    new ButtonInfo(WebshareLoadImageMethod.Auto, 'Auto')
  ];
 
  constructor(public injector: Injector, private serverApi: ServerApi, private templateManagementApiSvc: TemplateManagementApiSvc,
    public dialogService: DialogService, public validators: Validators, private webshareApiSvc: WebshareApiSvc) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('close', 'Close'),
    );
  }

  public ngOnInit(): void {
    this.showLoading = true;
    this.templateManagementApiSvc.getTemplates().subscribe( (data: any[]) => {
      this.showLoading = false;
      this.templates = data.map( (template: any) => ApiTools.convertTemplateFromResponseToClient(template));
      this.loadDataInGrid();
    }); 
  }

  public defineDialogModel(): void {}

  onChanges(): void {}

  public loadDataInGrid(): void {
    this.templateDataSource = new MatTableDataSource(this.templates);
    this.templateDataSource.sort = this.sort;
    this.noData = this.templateDataSource.connect().pipe(map( (data: any[]) => data.length === 0));
    this.templateDataSource.filterPredicate = function(data, filter: string): boolean {
      return data.name.toLowerCase().includes(filter);
    };
  }

  public deleteTemplate(template: ITemplate): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Delete Template' ],
      [ 'message', `Do you want to delete template ${template.name}? <br> Please note that placemarks using this template will be deleted as well.`]
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'No'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((data) => {
      if (data.userAction === 'yes') {
        const errorMessage = 'Error in deleting template. Please try later.';
        this.templateManagementApiSvc.deleteTemplate(template.id).subscribe((res: any) => { 
          if (res && res.hasErrors) {
            this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(errorMessage), errorMessage);
          } else {
            const deleteIndex = this.templates.findIndex((obj: ITemplate) => obj.id === template.id);
            if (deleteIndex !== -1) {
              this.templates.splice(deleteIndex, 1);
            }
            this.loadDataInGrid();
          }
        },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, errorMessage);
        });
      }
    });
  }

  public openTemplate(template: ITemplate): void {
    window.open(template.templateUrl, '_blank', 'height='+template.uiSize.height+',width='+template.uiSize.width);
  }

  public editTemplate(template: ITemplate): void {
    const inputsBinding: InputsBindingsModel = new Map()
    .set('dialogMode', EditorMode.EDIT)
    .set('templateId', template.id)
    .set('templateName', template.name)
    .set('templateWidth', template.uiSize.width)
    .set('templateHeight', template.uiSize.height)
    .set('file', template.originalFile)
    .set('fileName', template.originalFile);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditTemplateDialogComponent, DialogType.Modal, inputsBinding, null, 400);
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'add') {
        this.saveTemplate(data, EditorMode.EDIT);
      }
    });
  }

  public addNewTemplate() {
    const inputsBinding: InputsBindingsModel = new Map()
    .set('dialogMode', EditorMode.NEW);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditTemplateDialogComponent, DialogType.Modal, inputsBinding, null, 400);
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'add') {
       this.saveTemplate(data, EditorMode.NEW);
      }
    });
  }

  public saveTemplate(data: DialogModel, mode: EditorMode): void {
    let file = data.getData('file');
    let fileName = '';
    if (file instanceof File) {
      fileName = data.getData('fileName');
    } else {
      file = null;
    }

    const templateId = data.getData('templateId');
    const templateName = data.getData('templateName');
    const templateWidth = data.getData('templateWidth');
    const templateHeight = data.getData('templateHeight');  

    const errMessage: string = mode == EditorMode.EDIT ? 'Error in updating template. Please try later.' : 'Error in adding template. Please try later.'
    this.serverApi.statusBar.addNewStatus(mode == EditorMode.NEW ? MessagesBank.ADDING_TEMPLATE : MessagesBank.UPDATING_TEMPLATE);
    this.templateManagementApiSvc.saveTemplate(templateId, templateName, templateWidth, templateHeight, file, fileName).subscribe((res: any) => {
      this.serverApi.statusBar.removeStatus(mode == EditorMode.NEW ? MessagesBank.ADDING_TEMPLATE : MessagesBank.UPDATING_TEMPLATE);

      if (res.hasErrors) {
        this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(res.errorMessage), res.errorMessage);
      } else {
        const convertedTemplate: ITemplate = ApiTools.convertTemplateFromResponseToClient(res.template);
        if (mode == EditorMode.EDIT) {
          const index = this.templates.findIndex((obj: ITemplate) => obj.id === convertedTemplate.id);
          if (index != -1) {
            this.templates[index] = convertedTemplate;
          }
        } else {
          this.templates.push(convertedTemplate);
        }
        this.loadDataInGrid();
      }
    },
    (err) => {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, errMessage);
      this.serverApi.statusBar.removeStatus(mode == EditorMode.NEW ? MessagesBank.ADDING_TEMPLATE : MessagesBank.UPDATING_TEMPLATE);
    });
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public clearSearchStr(): void {
    this.searchStr = '';
    this.searchStrChanged('');
  }

  public searchStrChanged(filterValue: string): void {
    this.templateDataSource.filter = filterValue.trim().toLowerCase();
    this.filteredDataCount = this.templateDataSource.filteredData.length;
  }

  public updateDialogValidity(valid: boolean): void {
    this.isDialogValid = valid;
  }
}
