<div #dialogContainer class="dialog-container">
  <div class="dialog-content">
      <div #webGLContainer id="webgl-container"></div>
  </div>
  <div id="multipleObjectsMenu" class="multipleObjectsMenuContainer" [ngStyle]="getMenuPosition(multipleObjectsMenu)"
    *ngIf="multipleObjects.length" #multipleObjectsMenu>
    <ul id="menu" class="multipleObjectsList">
      <li *ngFor="let object of multipleObjects" class="list-group multipleObjectsMenuItem" [style.cursor]="navigatorManager.panoLinksLoaded ? 'pointer' : 'default'" (click)="objectClickedFromMenu(object)">
        <a class="list-group-item">
          <img id="placemarkIntersect" [src]="object.material.map.image.src" style="max-height:16px;max-width:16px;min-height:16px;" alt="placemark icon"/>
          <div class="multipleObjectsMenuItemText" #objectName insTooltip [cfg]="getToolTipConf(objectName, object.name)">{{ object.name }}</div>
        </a>
      </li>
    </ul>
  </div>
</div>

<div #tooltipContainer id="tooltip-container" class="object-tooltip">
</div>