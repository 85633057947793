import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICompanyInfo } from '../common/Models/UI/company-info.interface';
import { AppState } from '../Store/app.state/app.state';
import { ISite } from '../Store/sites.state/sites.model';

declare var San: any;

@Injectable({
  providedIn: 'root'
})
export class SiemensAnalyticsService {

  private companyInfo: ICompanyInfo;
  private site: ISite;

  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;

  constructor() {
    this.companyInfo$.subscribe((companyInfo: ICompanyInfo) => {
      if (companyInfo) {
        this.companyInfo = companyInfo;
      }
    });

    this.activeSite$.subscribe((site: ISite) => {
      if (site) {
        this.site = site;
      }
    });
  }

  private hasUserDisabledNecessaryData(): boolean {
    return this.companyInfo.dataPrivacyConsentInfo.dpe === 'decline';
  }

  private hasUserDisabledOptionalData(): boolean {
    return (!this.companyInfo.dataPrivacyConsentInfo.pep || this.companyInfo.dataPrivacyConsentInfo.pep === 'decline');
  }

  private logAnalyaticsEvent(eventName: string, eventProperties: Map<string, string>): void {
    const event = San.newEvent(eventName);
    if (eventProperties) {
      for (let property of eventProperties.entries()) {
        event.addProperty(property[0], property[1]);
      }
    }
    event.log();
  }

  public logEvent(eventName: string, recordSiteContext: boolean = true, site: ISite = null): void {
    if (!environment.useSan) {
      return;
    }
    this.site = site || this.site;
    const eventProperties = new Map<string, string>();
    eventProperties.set('Company', this.companyInfo.name.toLowerCase());
    if (recordSiteContext) {
      eventProperties.set('Site Id', this.site.id.toString());
      eventProperties.set('Site Name', this.site.name);
    } else {
      eventProperties.set('Site Id', '');
      eventProperties.set('Site Name', '');
    }
    this.logAnalyaticsEvent(eventName, eventProperties);
    eventName === 'INS_Logout' && this.endSession();
  }

  public initSession(): number {
    let status = 0;
    if (!environment.useSan || !this.companyInfo) {
      return status;
    }

    if (this.hasUserDisabledNecessaryData()) {
      San.disableNecessaryData();
    }
    if (this.hasUserDisabledOptionalData()) {
      San.disableOptionalData();
    }
    San.setProductKey(environment.sanProductKey);

    San.setCustomerIdentifier(this.companyInfo.soldToId ? this.companyInfo.soldToId : this.companyInfo.name.toLowerCase());
    San.setUserIdentifier(this.companyInfo.userName);

    San.addTag(San.tagCategoryClientProduct, "Intosite");
    San.addTag(San.tagCategoryEdition, environment.envName);

    if (environment.useSanInternalServer) {
      status = San.initialize('IntoSite', this.companyInfo.intositeVersion, this.companyInfo.intositeVersion, San.applicationLanguage_EN_US, true, 'assets/san_js/piwik.js');
    } else {
      status = San.initialize('IntoSite', this.companyInfo.intositeVersion, this.companyInfo.intositeVersion, San.applicationLanguage_EN_US);
    }

    San.addProductInfo('Number of Supported Languages', 1);

    return status;
  }

  public endSession(): void {
    if (!environment.useSan) {
      return;
    }
    San.endSession();
  }

}
